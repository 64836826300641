import React, { useState, useEffect } from 'react'
import { graphql, PageProps } from 'gatsby'
import { SubmitHandler } from 'react-hook-form'
import Layout from 'components/layout'
import AllDeviceStatuses from 'components/all-device-statuses'
import SignUpForm from 'components/sign-up-form'
import StartOverError from 'components/start-over-error'
import { useProgramFields } from 'hooks/use-program-fields'
import { useSignupObject } from 'hooks/use-signup-object'
import { updateSignup } from 'lib/uwp'
import { confirmationPath } from 'lib/paths'
import {
  formatEnrollmentObjForSubmission,
  getSignupErrorMsg,
  isEmergencyCanadianProgram,
  EnrollmentFormInputs,
} from 'lib/signup-form-helpers'
import {
  ContentfulComponentHeader,
  ContentfulLayout,
  ContentfulSectionForm,
  DeviceType,
} from 'types/contentful'
import * as Sentry from '@sentry/gatsby'

import * as styles from './form.module.css'

type DataProps = {
  contentfulPageForm: {
    layout: ContentfulLayout
    header: ContentfulComponentHeader
    form: ContentfulSectionForm
    domain: string
    deviceType?: DeviceType
    partnerSlug: string
    programSlug: string
    supportEmail: string
  }
}

const FormIndex: React.FC<PageProps<DataProps>> = (props) => {
  const {
    layout,
    header,
    form,
    domain,
    deviceType,
    partnerSlug,
    programSlug,
    supportEmail,
  } = props.data.contentfulPageForm

  const [signUpToken, setSignUpToken] = useState<string | null>()
  const [error, setError] = useState<string | null>()

  const { signupObj, loading: signupLoading } = useSignupObject({
    signUpToken,
    onError: () => {
      setError(
        'There was an error loading your information. Your token may have expired.'
      )
    },
  })
  const [pathParam, setPathParam] = useState<string | null>()
  const rejectionReason =
    signupObj?.eligibilityResult?.formFillingEnrollment?.reason

  const { programFields, loading: formConfigLoading } = useProgramFields({
    programKey: programSlug,
    onError: () => {
      setError(
        'There was an error displaying the form. Please try again later.'
      )
    },
  })
  const loading = signupLoading || formConfigLoading

  const onSubmit: SubmitHandler<EnrollmentFormInputs> = (formFields) => {
    if (!signUpToken || !signupObj) return

    const enrollmentForSubmission = formatEnrollmentObjForSubmission({
      existingEnrollment: signupObj.eligibilityResult.formFillingEnrollment,
      enrollmentFormInputs: formFields,
      program: programSlug,
    })

    updateSignup(signUpToken, enrollmentForSubmission, pathParam || null)
      .then((resp) => {
        console.log('resp', resp)
        window.location.href = confirmationPath({
          partner: partnerSlug,
          program: programSlug,
          path: pathParam,
          signUpToken,
        })
      })
      .catch((e) => {
        console.error(e)
        setError('There was an error submitting your information.')
        Sentry.captureException(e)
      })
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setSignUpToken(urlParams.get('signUpToken'))
    setPathParam(urlParams.get('path'))
  }, [])

  useEffect(() => {
    if (signupObj) {
      setError(getSignupErrorMsg(signupObj))
    }
  }, [signupObj])

  return (
    <Layout layout={layout} header={header}>
      <section className="form-container">
        <div className="container">
          <div className={styles.signUp}>
            {loading && <p>Loading...</p>}
            {!loading && error && (
              <StartOverError
                error={error}
                supportEmail={supportEmail}
                domain={domain}
                partnerSlug={partnerSlug}
                programSlug={programSlug}
                pathParam={pathParam}
              />
            )}
            {!loading && rejectionReason && pathParam === 'resubmit' && (
              <div className={styles.message} style={{ marginBottom: 40 }}>
                <p className={styles.rejection}>
                  This is why your application has been rejected:
                  <br />
                  {rejectionReason}
                  <br />
                  Please correct below and resubmit.
                </p>
              </div>
            )}
            {!loading && !error && signupObj && (
              <>
                <AllDeviceStatuses
                  signupObj={signupObj}
                  deviceType={deviceType}
                />

                <SignUpForm
                  isAmericanProgram={!isEmergencyCanadianProgram(programSlug)}
                  sectionForm={form}
                  signupObj={signupObj}
                  onSubmit={onSubmit}
                  programFields={programFields}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default FormIndex

export const pageFormQuery = graphql`
  query FormQuery($contentful_id: String!) {
    contentfulPageForm(contentful_id: { eq: $contentful_id }) {
      contentful_id
      domain
      partnerSlug
      deviceType
      programSlug
      layout {
        ...LayoutFields
      }
      header {
        ...HeaderFields
      }
      form {
        ...ContentfulSectionFormFields
      }
    }
  }
`
