import React from 'react'

import * as styles from './signup-device.module.css'
import ValidIcon from './assets/green-check.svg'
import InvalidIcon from './assets/red-x.svg'
import { DeviceLanguage } from 'lib/signup-form-helpers'

type SignupDeviceProps = {
  deviceId: string
  deviceLanguage: DeviceLanguage
}

export const EligibleDevice = ({
  deviceId,
  deviceLanguage,
}: SignupDeviceProps) => {
  return (
    <li className={`${styles.device} ${styles.eligible}`}>
      <ValidIcon />
      <p>
        {deviceLanguage.singularTitleCase} - {deviceId}
      </p>
    </li>
  )
}

export const IneligibleDevice = ({
  deviceId,
  deviceLanguage,
}: SignupDeviceProps) => {
  return (
    <li className={`${styles.device} ${styles.ineligible}`}>
      <InvalidIcon />
      <p>
        {deviceLanguage.singularTitleCase} - {deviceId}
      </p>
    </li>
  )
}
