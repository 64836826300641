import React from 'react'
import { EligibleDevice, IneligibleDevice } from 'components/signup-device'

import * as styles from './signup-device-list.module.css'
import { DeviceLanguage } from 'lib/signup-form-helpers'

type DeviceInfo = {
  deviceId: string
  deviceLanguage: DeviceLanguage
}

type SignupDeviceListProps = {
  devices: DeviceInfo[]
  heading: string
  successful: boolean
}

const SignupDeviceList = ({
  devices,
  heading,
  successful,
}: SignupDeviceListProps) => {
  return (
    <>
      <h2>{heading}</h2>
      <ul className={styles.devices}>
        {devices.map(({ deviceId, deviceLanguage }) =>
          successful ? (
            <EligibleDevice
              key={deviceId}
              deviceId={deviceId}
              deviceLanguage={deviceLanguage}
            />
          ) : (
            <IneligibleDevice
              key={deviceId}
              deviceId={deviceId}
              deviceLanguage={deviceLanguage}
            />
          )
        )}
      </ul>
    </>
  )
}

export default SignupDeviceList
