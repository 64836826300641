import React from 'react'
import SignupDeviceList from 'components/signup-device-list'
import {
  DeviceLanguage,
  formatHvacSystemTypes,
  getDeviceLanguage,
} from 'lib/signup-form-helpers'
import { UWPSignup } from 'types/uwp'
import { DeviceType } from 'types/contentful'

const getDeviceInfoList = (
  deviceIds: string[],
  deviceLanguage: DeviceLanguage
) => {
  return deviceIds.map((deviceId) => ({ deviceId, deviceLanguage }))
}

const AllDeviceStatuses = ({
  signupObj,
  deviceType,
}: {
  signupObj: UWPSignup
  deviceType?: DeviceType
}) => {
  const devicesByStatus =
    signupObj?.eligibilityResult?.enrollmentMessage?.devicesByStatus
  const deviceLanguage = getDeviceLanguage(deviceType)

  return (
    <>
      {devicesByStatus?.ELIGIBLE && (
        <SignupDeviceList
          devices={getDeviceInfoList(
            devicesByStatus.ELIGIBLE.identifiers,
            deviceLanguage
          )}
          heading={`These ${deviceLanguage.pluralLowerCase} will be enrolled:`}
          successful={true}
        />
      )}
      {devicesByStatus?.INELIGIBLE_DR && (
        <SignupDeviceList
          devices={getDeviceInfoList(
            devicesByStatus.INELIGIBLE_DR.identifiers,
            deviceLanguage
          )}
          heading={`These ${deviceLanguage.pluralLowerCase} are not compatible with Demand Response:`}
          successful={false}
        />
      )}
      {devicesByStatus?.INELIGIBLE_MODEL && (
        <SignupDeviceList
          devices={getDeviceInfoList(
            devicesByStatus.INELIGIBLE_MODEL.identifiers,
            deviceLanguage
          )}
          heading={`The following ${deviceLanguage.pluralLowerCase} are not eligible to participate:`}
          successful={false}
        />
      )}
      {devicesByStatus?.INELIGIBLE_OFFLINE && (
        <SignupDeviceList
          devices={getDeviceInfoList(
            devicesByStatus.INELIGIBLE_OFFLINE.identifiers,
            deviceLanguage
          )}
          heading={`The following ${deviceLanguage.pluralLowerCase} are offline, so they are not eligible to participate:`}
          successful={false}
        />
      )}
      {devicesByStatus?.INELIGIBLE_INSTALL_DATE && (
        <SignupDeviceList
          devices={getDeviceInfoList(
            devicesByStatus.INELIGIBLE_INSTALL_DATE.identifiers,
            deviceLanguage
          )}
          heading={`The following ${
            deviceLanguage.pluralLowerCase
          } were installed before ${
            devicesByStatus.INELIGIBLE_INSTALL_DATE.metadata.minimumInstallDate
              ? new Date(
                  devicesByStatus.INELIGIBLE_INSTALL_DATE.metadata.minimumInstallDate
                ).toLocaleDateString('sv')
              : "the program's minimum install date"
          }, so they are not eligible to participate:`}
          successful={false}
        />
      )}
      {devicesByStatus?.INELIGIBLE_REQUIRED_SERVICES && (
        <SignupDeviceList
          devices={getDeviceInfoList(
            devicesByStatus.INELIGIBLE_REQUIRED_SERVICES.identifiers,
            deviceLanguage
          )}
          heading={`The following ${deviceLanguage.pluralLowerCase} are not eligible to participate:`}
          successful={false}
        />
      )}
      {devicesByStatus?.INELIGIBLE_HVAC_SYSTEM && (
        <SignupDeviceList
          devices={getDeviceInfoList(
            devicesByStatus.INELIGIBLE_HVAC_SYSTEM.identifiers,
            deviceLanguage
          )}
          heading={`The following ${
            deviceLanguage.pluralLowerCase
          } do not control a(n) ${formatHvacSystemTypes(
            devicesByStatus.INELIGIBLE_HVAC_SYSTEM.metadata.hvacSystemTypes ||
              []
          )} system, so they are not eligible to participate:`}
          successful={false}
        />
      )}
    </>
  )
}

export default AllDeviceStatuses
